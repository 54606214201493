import React from 'react';

const PDFViewer = ({ pdfUrl }) => {
const viewerUrl = `https://docs.google.com/gview?url=${pdfUrl}&embedded=true`;
  return (
    <div className="flex flex-col items-center">
      <div className="rounded-lg  w-full h-[800px]">
        <iframe
          src={viewerUrl}
          title="PDF Viewer"
          type="application/pdf"
          className="w-full h-full rounded-lg"
          style={{ border: 'none' }}
        />
      </div>
    </div>
  );
};

export default PDFViewer; 