import React, { useState } from "react";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../../commons/Loader";
import PDFViewer from "../../../../pages/PDFViewer";
import { useLocation } from "react-router-dom";

const GuidDetail = () => {
  const { id } = useParams();
  const location = useLocation();
  const { guide } = location.state 
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <>
      {!guide ? (
        <Loader isLoading={true} />
      ) : (
        <div className="col-span-12">
          <div className="flex items-center gap-[6px] xl:gap-[10px] mb-5 xl:mb-[30px]">
            <div className="flex items-center gap-[10px]">
              <ArrowLeftIcon
                className="w-6 h-6 text-[#485868] cursor-pointer"
                onClick={handleGoBack}
              />
              <h3
                className="text-xl xl:text-2xl font-normal leading-[130%] text-[#485868] cursor-pointer"
                onClick={handleGoBack}
              >
                Guides
              </h3>
            </div>
            <span className="text-[#485868]">&#8226;</span>
            <h3 className="text-xl xl:text-2xl font-bold leading-[130%] text-customGray">
              {guide?.title}
            </h3>
          </div>
          <div className="w-full">
          <div className="mb-12 bg-white rounded-lg">
            <PDFViewer pdfUrl={guide.pdf_file} />
          </div>
          </div>
        </div>
      )}
    </>
  );
};

export default GuidDetail;
